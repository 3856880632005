import React from "react";
import Layout from "@/components/layout/Layout";

export default function Login() {
  return (
    <Layout>
      Login page
    </Layout>
  )
}
